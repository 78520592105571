import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import BookingWidget from "./components/BookingWidget";
import FeedbackPage from "./components/FeedbackPage";
import SubmissionForm from "./components/SubmissionForm";
import DeleteAccountPage from "./components/DeleteAccount";
import PrivacyPolicyPage from "./components/PrivacyPolicy";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/:wexNumber" element={<BookingWidget />} />
        <Route path="/feedback/:wexId" element={<FeedbackPage />} />
        <Route path="/submission/:id" element={<SubmissionForm />} />
        <Route path="/delete_account" element={<DeleteAccountPage />} />
        <Route path="/privacy_policy" element={<PrivacyPolicyPage />} />
        <Route path="/" element={<Navigate to="/default" replace />} />
      </Routes>
    </Router>
  );
}

export default App;
