import React from 'react';
import { 
  Container, Typography, Paper, Box, List, ListItem, 
  ListItemIcon, ListItemText, Divider, Button, ThemeProvider, createTheme 
} from '@mui/material';
import { Mail as MailIcon, Info as InfoIcon, Warning as WarningIcon } from '@mui/icons-material';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#dc004e',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h4: {
      fontWeight: 700,
    },
    h6: {
      fontWeight: 600,
    },
  },
});

const DeleteAccountPage = () => {
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="md" sx={{ mt: 8, mb: 8 }}>
        <Paper elevation={3} sx={{ p: 4, borderRadius: 2 }}>
          <Typography variant="h4" sx={{ mb: 4, textAlign: 'center', color: 'primary.main' }}>
            Delete Your FC PRO Account
          </Typography>

          <Typography variant="body1" sx={{ mb: 3 }}>
            We're sorry to see you go. If you wish to delete your account, please follow the instructions below:
          </Typography>

          <Box sx={{ bgcolor: 'primary.light', p: 2, borderRadius: 1, mb: 4 }}>
            <List>
              <ListItem>
                <ListItemIcon><MailIcon color="primary" /></ListItemIcon>
                <ListItemText 
                  primary={<Typography variant="subtitle1" fontWeight="bold">Send an email to delete your account</Typography>}
                  secondary="Email aschwab@warehouseexchange.com to request account deletion"
                />
              </ListItem>
            </List>
          </Box>

          <Divider sx={{ my: 3 }} />

          <Typography variant="h6" sx={{ mt: 3, mb: 2, color: 'primary.main' }}>
            What happens when you request account deletion:
          </Typography>
          <List>
            <ListItem>
              <ListItemIcon><InfoIcon color="success" /></ListItemIcon>
              <ListItemText primary="The WEx team will swiftly process your request" />
            </ListItem>
            <ListItem>
              <ListItemIcon><InfoIcon color="success" /></ListItemIcon>
              <ListItemText primary="All your information will be completely deleted" />
            </ListItem>
            <ListItem>
              <ListItemIcon><InfoIcon color="success" /></ListItemIcon>
              <ListItemText primary="No data will be retained after account deletion" />
            </ListItem>
          </List>

          <Box sx={{ mt: 4, bgcolor: 'warning.light', p: 2, borderRadius: 1 }}>
            <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
              <WarningIcon color="warning" sx={{ mr: 1 }} />
              Please note: Once your account is deleted, this action cannot be undone. If you have any questions or concerns before proceeding, please contact our support team.
            </Typography>
          </Box>

          <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
            <Button 
              variant="contained" 
              color="secondary" 
              startIcon={<MailIcon />}
              href="mailto:aschwab@warehouseexchange.com?subject=Account%20Deletion%20Request"
            >
              Request Account Deletion
            </Button>
          </Box>
        </Paper>
      </Container>
    </ThemeProvider>
  );
};

export default DeleteAccountPage;