import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Paper,
  ThemeProvider,
  createTheme,
  alpha,
  CircularProgress,
  Snackbar,
  Alert,
  AlertColor,
} from '@mui/material';
import { motion } from 'framer-motion';
import { API_URL } from '../constants';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
    secondary: {
      main: '#f50057',
    },
    background: {
      default: '#f5f5f5',
    },
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          textTransform: 'none',
          fontWeight: 600,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 16,
        },
      },
    },
  },
});

interface SnackbarState {
  open: boolean;
  message: string;
  severity: AlertColor;
}

const FeedbackPage: React.FC = () => {
  const { wexId } = useParams<{ wexId: string }>();
  const [feedback, setFeedback] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [snackbar, setSnackbar] = useState<SnackbarState>({ open: false, message: '', severity: 'success' });

  const handleFeedbackChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFeedback(event.target.value);
  };

  const handleSubmit = async () => {
    if (!feedback.trim()) {
      setSnackbar({ open: true, message: 'Please enter your feedback before submitting.', severity: 'error' });
      return;
    }

    setLoading(true);

    try {
      const response = await fetch(`${API_URL}/provide_feedback`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ wex_id: wexId, feedback }),
      });

      if (response.ok) {
        setSnackbar({ open: true, message: 'Feedback submitted successfully!', severity: 'success' });
        setFeedback('');
      } else {
        throw new Error('Failed to submit feedback');
      }
    } catch (error) {
      console.error('Error submitting feedback:', error);
      setSnackbar({ open: true, message: 'Failed to submit feedback. Please try again.', severity: 'error' });
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="md" sx={{ mt: 5, mb: 5 }}>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <Paper elevation={3} sx={{ p: 4, borderRadius: 2, background: 'linear-gradient(145deg, #ffffff 0%, #f0f0f0 100%)' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', mb: 4 }}>
              <Box sx={{ width: '20%', display: 'flex', justifyContent: 'center' }}>
                <img src="/logo_black.svg" alt="logo" style={{ width: '60%' }} />
              </Box>
            </Box>
            <Typography variant="h4" color="primary" align="center" gutterBottom sx={{ fontWeight: 700 }}>
              Provide Feedback
            </Typography>
            <Typography variant="subtitle1" align="center" gutterBottom sx={{ color: '#666' }}>
              WEx #: {wexId}
            </Typography>
            <Box sx={{ mt: 4 }}>
              <TextField
                fullWidth
                multiline
                rows={6}
                variant="outlined"
                label="Your Feedback"
                value={feedback}
                onChange={handleFeedbackChange}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: alpha(theme.palette.primary.main, 0.3),
                    },
                    '&:hover fieldset': {
                      borderColor: theme.palette.primary.main,
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: theme.palette.primary.main,
                    },
                  },
                }}
              />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
              <Button 
                variant="contained" 
                color="primary" 
                onClick={handleSubmit}
                disabled={loading}
                sx={{ 
                  py: 1.5, 
                  px: 4, 
                  fontSize: '1rem',
                  boxShadow: '0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)',
                  '&:hover': {
                    boxShadow: '0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08)',
                  },
                }}
              >
                {loading ? <CircularProgress size={24} color="inherit" /> : 'Submit Feedback'}
              </Button>
            </Box>
          </Paper>
        </motion.div>
      </Container>
      <Snackbar open={snackbar.open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
};

export default FeedbackPage;